import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies';

Vue.use(VueRouter)

let role_id = VueCookies.get('role_id')
const path = (role_id == '30') ? '/dashboard' : '/index'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('../views/Layout.vue'),
	redirect: path,
	meta: {
	  title: '主页'
	},
	children: [
		{
		  path: '/index',
		  component: () =>  (role_id == 30) ? import('../views/dashboard/teacher/index.vue') : import('../views/dashboard/manage/index.vue'),
		  name: 'Index',
		  meta: {
			title: '工作台'
		  },
		  children: null
		},
		{
		  path: '/dashboard',
		  component: () => import('../views/dashboard/teacher/index.vue'),
		  name: 'Dashboard',
		  meta: {
			title: '我的积分'
		  },
		  children: null
		},
		{
		  path: '/setting',
		  component: () => import('../views/system/setting/index.vue'),
		  name: 'Setting',
		  meta: {
			title: '系统设置'
		  },
		  children: null
		},
		{
		  path: '/admin_role',
		  component: () => import('../views/system/admin_role/index.vue'),
		  name: 'AdminRole',
		  meta: {
			title: '角色管理'
		  },
		  children: null
		},
		{
		  path: '/admin',
		  component: () => import('../views/system/admin/index.vue'),
		  name: 'Admin',
		  meta: {
			title: '人员列表'
		  },
		  children: null
		},
		{
		  path: '/operation',
		  component: () => import('../views/system/operation/index.vue'),
		  name: 'Operation',
		  meta: {
			title: '权限管理'
		  },
		  children: null
		},
		{
		  path: '/operation_log',
		  component: () => import('../views/system/operation_log/index.vue'),
		  name: 'OperationLog',
		  meta: {
			title: '系统日志'
		  },
		  children: null
		},
		{
		  path: '/account',
		  component: () => import('../views/system/account/index.vue'),
		  name: 'Setting',
		  meta: {
			title: '系统设置'
		  },
		  children: null
		},
		{
		  path: '/images',
		  component: () => import('../views/system/images/index.vue'),
		  name: 'Images',
		  meta: {
			title: '图片管理'
		  },
		  children: null
		},
		{
		  path: '/semester',
		  component: () => import('../views/common/semester/index.vue'),
		  name: 'Semester',
		  meta: {
			title: '学期管理'
		  },
		  children: null
		},
		{
		  path: '/semester_list',
		  component: () => import('../views/common/semester/list.vue'),
		  name: 'SemesterList',
		  meta: {
			title: '本期明细'
		  },
		  children: null
		},
		{
		  path: '/template_params',
		  component: () => import('../views/student/template_params/index.vue'),
		  name: 'TemplateParams',
		  meta: {
			title: '模板参数'
		  },
		  children: null
		},
		{
		  path: '/template',
		  component: () => import('../views/student/template/index.vue'),
		  name: 'Template',
		  meta: {
			title: '模板管理'
		  },
		  children: null
		},
		{
		  path: '/wj',
		  component: () => import('../views/student/wj/index.vue'),
		  name: 'Wj',
		  meta: {
			title: '问卷管理'
		  },
		  children: null
		},
		{
		  path: '/teacher',
		  component: () => import('../views/common/teacher/index.vue'),
		  name: 'Teacher',
		  meta: {
			title: '老师管理'
		  },
		  children: null
		},
		{
		  path: '/teacher_detail',
		  component: () => import('../views/common/teacher/detail.vue'),
		  name: 'TeacherDetail',
		  meta: {
			title: '老师详情'
		  },
		  children: null
		},
		{
		  path: '/dkl',
		  component: () => import('../views/examine/dkl/index.vue'),
		  name: 'Dkl',
		  meta: {
			title: '代课量'
		  },
		  children: null
		},
		{
		  path: '/dkl_list',
		  component: () => import('../views/examine/dkl/list.vue'),
		  name: 'DklList',
		  meta: {
			title: '代课量列表'
		  },
		  children: null
		},
		{
		  path: '/dkl_view',
		  component: () => import('../views/examine/dkl/view.vue'),
		  name: 'DklView',
		  meta: {
			title: '代课量'
		  },
		  children: null
		},
		{
		  path: '/jxcg',
		  component: () => import('../views/examine/jxcg/index.vue'),
		  name: 'Jxcg',
		  meta: {
			title: '教学常规'
		  },
		  children: null
		},
		{
		  path: '/jxcg_list',
		  component: () => import('../views/examine/jxcg/list.vue'),
		  name: 'JxcgList',
		  meta: {
			title: '教学常规考核列表'
		  },
		  children: null
		},
		{
		  path: '/jxcg_view',
		  component: () => import('../views/examine/jxcg/view.vue'),
		  name: 'JxcgView',
		  meta: {
			title: '教学常规'
		  },
		  children: null
		},
		{
		  path: '/jtbk',
		  component: () => import('../views/examine/jtbk/index.vue'),
		  name: 'Jtbk',
		  meta: {
			title: '集体备课'
		  },
		  children: null
		},
		{
		  path: '/jtbk_list',
		  component: () => import('../views/examine/jtbk/list.vue'),
		  name: 'JtbkList',
		  meta: {
			title: '集体备课考核列表'
		  },
		  children: null
		},
		{
		  path: '/jtbk_view',
		  component: () => import('../views/examine/jtbk/view.vue'),
		  name: 'JtbkView',
		  meta: {
			title: '集体备课'
		  },
		  children: null
		},
		{
		  path: '/jyhd',
		  component: () => import('../views/examine/jyhd/index.vue'),
		  name: 'Jyhd',
		  meta: {
			title: '教研活动'
		  },
		  children: null
		},
		{
		  path: '/jyhd_list',
		  component: () => import('../views/examine/jyhd/list.vue'),
		  name: 'JyhdList',
		  meta: {
			title: '教研活动列表'
		  },
		  children: null
		},
		{
		  path: '/jyhd_view',
		  component: () => import('../views/examine/jyhd/view.vue'),
		  name: 'JyhdView',
		  meta: {
			title: '教研活动'
		  },
		  children: null
		},
		{
		  path: '/hdzd',
		  component: () => import('../views/examine/hdzd/index.vue'),
		  name: 'Hdzd',
		  meta: {
			title: '活动指导'
		  },
		  children: null
		},
		{
		  path: '/hdzd_list',
		  component: () => import('../views/examine/hdzd/list.vue'),
		  name: 'HdzdList',
		  meta: {
			title: '活动指导列表'
		  },
		  children: null
		},
		{
		  path: '/hdzd_view',
		  component: () => import('../views/examine/hdzd/view.vue'),
		  name: 'HdzdView',
		  meta: {
			title: '活动指导'
		  },
		  children: null
		},
		{
		  path: '/jxcj',
		  component: () => import('../views/examine/jxcj/index.vue'),
		  name: 'Jxcj',
		  meta: {
			title: '教学成绩'
		  },
		  children: null
		},
		{
		  path: '/jxcj_list',
		  component: () => import('../views/examine/jxcj/list.vue'),
		  name: 'JxcjList',
		  meta: {
			title: '教学成绩列表'
		  },
		  children: null
		},
		{
		  path: '/jxcj_view',
		  component: () => import('../views/examine/jxcj/view.vue'),
		  name: 'JxcjView',
		  meta: {
			title: '教学成绩'
		  },
		  children: null
		},
		{
		  path: '/jsry',
		  component: () => import('../views/examine/jsry/index.vue'),
		  name: 'Jsry',
		  meta: {
			title: '教师荣誉'
		  },
		  children: null
		},
		{
		  path: '/jsry_list',
		  component: () => import('../views/examine/jsry/list.vue'),
		  name: 'JsryList',
		  meta: {
			title: '教师荣誉列表'
		  },
		  children: null
		},
		{
		  path: '/jsry_view',
		  component: () => import('../views/examine/jsry/view.vue'),
		  name: 'JsryView',
		  meta: {
			title: '教师荣誉'
		  },
		  children: null
		},
		{
		  path: '/jzjx',
		  component: () => import('../views/examine/jzjx/index.vue'),
		  name: 'Jzjx',
		  meta: {
			title: '兼职绩效'
		  },
		  children: null
		},
		{
		  path: '/jzjx_list',
		  component: () => import('../views/examine/jzjx/list.vue'),
		  name: 'JzjxList',
		  meta: {
			title: '兼职绩效列表'
		  },
		  children: null
		},
		{
		  path: '/jzjx_view',
		  component: () => import('../views/examine/jzjx/view.vue'),
		  name: 'JzjxView',
		  meta: {
			title: '兼职绩效'
		  },
		  children: null
		},
		{
		  path: '/dwjk',
		  component: () => import('../views/examine/dwjk/index.vue'),
		  name: 'Dwjk',
		  meta: {
			title: '对外监考'
		  },
		  children: null
		},
		{
		  path: '/dwjk_list',
		  component: () => import('../views/examine/dwjk/list.vue'),
		  name: 'DwjkList',
		  meta: {
			title: '对外监考列表'
		  },
		  children: null
		},
		{
		  path: '/dwjk_view',
		  component: () => import('../views/examine/dwjk/view.vue'),
		  name: 'DwjkView',
		  meta: {
			title: '对外监考'
		  },
		  children: null
		},
		{
		  path: '/jlcf',
		  component: () => import('../views/examine/jlcf/index.vue'),
		  name: 'Jlcf',
		  meta: {
			title: '纪律处罚'
		  },
		  children: null
		},
		{
		  path: '/jlcf_list',
		  component: () => import('../views/examine/jlcf/list.vue'),
		  name: 'JlcfList',
		  meta: {
			title: '纪律处罚列表'
		  },
		  children: null
		},
		{
		  path: '/jlcf_view',
		  component: () => import('../views/examine/jlcf/view.vue'),
		  name: 'JlcfView',
		  meta: {
			title: '纪律处罚'
		  },
		  children: null
		},
		{
		  path: '/ungk',
		  component: () => import('../views/examine/ungk/index.vue'),
		  name: 'Ungk',
		  meta: {
			title: '非高考科目'
		  },
		  children: null
		},
		{
		  path: '/ungk_list',
		  component: () => import('../views/examine/ungk/list.vue'),
		  name: 'UngkList',
		  meta: {
			title: '非高考科目列表'
		  },
		  children: null
		},
		{
		  path: '/ungk_view',
		  component: () => import('../views/examine/ungk/view.vue'),
		  name: 'UngkView',
		  meta: {
			title: '非高考科目'
		  },
		  children: null
		},
		{
		  path: '/nianji',
		  component: () => import('../views/common/nianji/index.vue'),
		  name: 'Nianji',
		  meta: {
			title: '年级管理'
		  },
		  children: null
		},
		{
		  path: '/xueke',
		  component: () => import('../views/common/xueke/index.vue'),
		  name: 'Xueke',
		  meta: {
			title: '学科管理'
		  },
		  children: null
		},
		{
		  path: '/user_sfk',
		  component: () => import('../views/jysq_apply/user_sfk/index.vue'),
		  name: 'Sfk',
		  meta: {
			title: '示范课'
		  },
		  children: null
		},
		{
		  path: '/sfk_edit',
		  component: () => import('../views/jysq_apply/user_sfk/edit.vue'),
		  name: 'SfkEdit',
		  meta: {
			title: '示范课申请'
		  },
		  children: null
		},
		{
		  path: '/sfk_operate',
		  component: () => import('../views/jysq_apply/user_sfk/operate.vue'),
		  name: 'SfkOperate',
		  meta: {
			title: '示范课审核'
		  },
		  children: null
		},
		{
		  path: '/sfk_detail',
		  component: () => import('../views/jysq_apply/user_sfk/detail.vue'),
		  name: 'SfkDetail',
		  meta: {
			title: '详情'
		  },
		  children: null
		},
		{
		  path: '/user_ktyj',
		  component: () => import('../views/jysq_apply/user_ktyj/index.vue'),
		  name: 'UserKtyj',
		  meta: {
			title: '课题研究'
		  },
		  children: null
		},
		{
		  path: '/ktyj_edit',
		  component: () => import('../views/jysq_apply/user_ktyj/edit.vue'),
		  name: 'KtyjEdit',
		  meta: {
			title: '课题研究申请'
		  },
		  children: null
		},
		{
		  path: '/ktyj_operate',
		  component: () => import('../views/jysq_apply/user_ktyj/operate.vue'),
		  name: 'KtyjOperate',
		  meta: {
			title: '示范课审核'
		  },
		  children: null
		},
		{
		  path: '/ktyj_detail',
		  component: () => import('../views/jysq_apply/user_ktyj/detail.vue'),
		  name: 'KtyjDetail',
		  meta: {
			title: '详情'
		  },
		  children: null
		},
		{
		  path: '/user_jspx',
		  component: () => import('../views/jysq_apply/user_jspx/index.vue'),
		  name: 'UserJspx',
		  meta: {
			title: '教师培训'
		  },
		  children: null
		},
		{
		  path: '/jspx_edit',
		  component: () => import('../views/jysq_apply/user_jspx/edit.vue'),
		  name: 'jspxEdit',
		  meta: {
			title: '教师培训申请'
		  },
		  children: null
		},
		{
		  path: '/jspx_operate',
		  component: () => import('../views/jysq_apply/user_jspx/operate.vue'),
		  name: 'jspxOperate',
		  meta: {
			title: '教师培训审核'
		  },
		  children: null
		},
		{
		  path: '/jspx_detail',
		  component: () => import('../views/jysq_apply/user_jspx/detail.vue'),
		  name: 'jspxDetail',
		  meta: {
			title: '教师培训详情'
		  },
		  children: null
		},
		{
		  path: '/user_lwfb',
		  component: () => import('../views/jysq_apply/user_lwfb/index.vue'),
		  name: 'UserLwfb',
		  meta: {
			title: '论文发表'
		  },
		  children: null
		},
		{
		  path: '/lwfb_edit',
		  component: () => import('../views/jysq_apply/user_lwfb/edit.vue'),
		  name: 'LwfbEdit',
		  meta: {
			title: '论文发表申请'
		  },
		  children: null
		},
		{
		  path: '/lwfb_operate',
		  component: () => import('../views/jysq_apply/user_lwfb/operate.vue'),
		  name: 'LwfbOperate',
		  meta: {
			title: '论文发表审核'
		  },
		  children: null
		},
		{
		  path: '/lwfb_detail',
		  component: () => import('../views/jysq_apply/user_lwfb/detail.vue'),
		  name: 'LwfbDetail',
		  meta: {
			title: '论文发表详情'
		  },
		  children: null
		},
		{
		  path: '/user_jxhj',
		  component: () => import('../views/jysq_apply/user_jxhj/index.vue'),
		  name: 'UserJxhj',
		  meta: {
			title: '教学获奖'
		  },
		  children: null
		},
		{
		  path: '/jxhj_edit',
		  component: () => import('../views/jysq_apply/user_jxhj/edit.vue'),
		  name: 'JxhjEdit',
		  meta: {
			title: '教学获奖申请'
		  },
		  children: null
		},
		{
		  path: '/jxhj_operate',
		  component: () => import('../views/jysq_apply/user_jxhj/operate.vue'),
		  name: 'JxhjOperate',
		  meta: {
			title: '教学获奖审核'
		  },
		  children: null
		},
		{
		  path: '/jxhj_detail',
		  component: () => import('../views/jysq_apply/user_jxhj/detail.vue'),
		  name: 'JxhjDetail',
		  meta: {
			title: '教学获奖详情'
		  },
		  children: null
		},
		{
		  path: '/user_aszd',
		  component: () => import('../views/zdsq_apply/user_aszd/index.vue'),
		  name: 'UserAszd',
		  meta: {
			title: '奥赛指导'
		  },
		  children: null
		},
		{
		  path: '/aszd_operate',
		  component: () => import('../views/zdsq_apply/user_aszd/operate.vue'),
		  name: 'AszdOperate',
		  meta: {
			title: '奥赛指导审核'
		  },
		  children: null
		},
		{
		  path: '/user_faszd',
		  component: () => import('../views/zdsq_apply/user_faszd/index.vue'),
		  name: 'UserFaszd',
		  meta: {
			title: '非奥赛指导'
		  },
		  children: null
		},
		{
		  path: '/faszd_edit',
		  component: () => import('../views/zdsq_apply/user_faszd/edit.vue'),
		  name: 'FaszdEdit',
		  meta: {
			title: '非奥赛指导申请'
		  },
		  children: null
		},
		{
		  path: '/faszd_operate',
		  component: () => import('../views/zdsq_apply/user_faszd/operate.vue'),
		  name: 'FaszdOperate',
		  meta: {
			title: '非奥赛指导审核'
		  },
		  children: null
		},
		{
		  path: '/faszd_detail',
		  component: () => import('../views/zdsq_apply/user_faszd/detail.vue'),
		  name: 'FaszdDetail',
		  meta: {
			title: '非奥赛指导详情'
		  },
		  children: null
		},
		{
		  path: '/user_shsjhd',
		  component: () => import('../views/zdsq_apply/user_shsjhd/index.vue'),
		  name: 'UserShsjhd',
		  meta: {
			title: '社会实践活动'
		  },
		  children: null
		},
		{
		  path: '/shsjhd_edit',
		  component: () => import('../views/zdsq_apply/user_shsjhd/edit.vue'),
		  name: 'ShsjhdEdit',
		  meta: {
			title: '社会实践活动申请'
		  },
		  children: null
		},
		{
		  path: '/shsjhd_operate',
		  component: () => import('../views/zdsq_apply/user_shsjhd/operate.vue'),
		  name: 'ShsjhdOperate',
		  meta: {
			title: '社会实践活动审核'
		  },
		  children: null
		},
		{
		  path: '/shsjhd_detail',
		  component: () => import('../views/zdsq_apply/user_shsjhd/detail.vue'),
		  name: 'ShsjhdDetail',
		  meta: {
			title: '社会实践活动详情'
		  },
		  children: null
		},
		{
		  path: '/user_yjxx',
		  component: () => import('../views/zdsq_apply/user_yjxx/index.vue'),
		  name: 'UserYjxx',
		  meta: {
			title: '研究性学习'
		  },
		  children: null
		},
		{
		  path: '/yjxx_edit',
		  component: () => import('../views/zdsq_apply/user_yjxx/edit.vue'),
		  name: 'YjxxEdit',
		  meta: {
			title: '研究性学习申请'
		  },
		  children: null
		},
		{
		  path: '/yjxx_operate',
		  component: () => import('../views/zdsq_apply/user_yjxx/operate.vue'),
		  name: 'YjxxOperate',
		  meta: {
			title: '研究性学习审核'
		  },
		  children: null
		},
		{
		  path: '/yjxx_detail',
		  component: () => import('../views/zdsq_apply/user_yjxx/detail.vue'),
		  name: 'YjxxDetail',
		  meta: {
			title: '研究性学习详情'
		  },
		  children: null
		},
		{
		  path: '/user_stzd',
		  component: () => import('../views/zdsq_apply/user_stzd/index.vue'),
		  name: 'UserStzd',
		  meta: {
			title: '社团指导'
		  },
		  children: null
		},
		{
		  path: '/stzd_edit',
		  component: () => import('../views/zdsq_apply/user_stzd/edit.vue'),
		  name: 'StzdEdit',
		  meta: {
			title: '社团指导申请'
		  },
		  children: null
		},
		{
		  path: '/stzd_operate',
		  component: () => import('../views/zdsq_apply/user_stzd/operate.vue'),
		  name: 'StzdOperate',
		  meta: {
			title: '社团指导审核'
		  },
		  children: null
		},
		{
		  path: '/stzd_detail',
		  component: () => import('../views/zdsq_apply/user_stzd/detail.vue'),
		  name: 'StzdDetail',
		  meta: {
			title: '社团指导详情'
		  },
		  children: null
		},
		{
		  path: '/user_jscj',
		  component: () => import('../views/jxcj_apply/user_jscj/index.vue'),
		  name: 'UserJscj',
		  meta: {
			title: '竞赛成绩'
		  },
		  children: null
		},
		{
		  path: '/jscj_edit',
		  component: () => import('../views/jxcj_apply/user_jscj/edit.vue'),
		  name: 'JscjEdit',
		  meta: {
			title: '竞赛成绩申请'
		  },
		  children: null
		},
		{
		  path: '/jscj_operate',
		  component: () => import('../views/jxcj_apply/user_jscj/operate.vue'),
		  name: 'JscjOperate',
		  meta: {
			title: '竞赛成绩审核'
		  },
		  children: null
		},
		{
		  path: '/jscj_detail',
		  component: () => import('../views/jxcj_apply/user_jscj/detail.vue'),
		  name: 'JscjDetail',
		  meta: {
			title: '竞赛成绩详情'
		  },
		  children: null
		},
		{
		  path: '/user_hjmc',
		  component: () => import('../views/jsry_apply/user_hjmc/index.vue'),
		  name: 'UserHjmc',
		  meta: {
			title: '获奖名称'
		  },
		  children: null
		},
		{
		  path: '/hjmc_edit',
		  component: () => import('../views/jsry_apply/user_hjmc/edit.vue'),
		  name: 'HjmcEdit',
		  meta: {
			title: '获奖名称申请'
		  },
		  children: null
		},
		{
		  path: '/hjmc_operate',
		  component: () => import('../views/jsry_apply/user_hjmc/operate.vue'),
		  name: 'HjmcOperate',
		  meta: {
			title: '获奖名称审核'
		  },
		  children: null
		},
		{
		  path: '/hjmc_detail',
		  component: () => import('../views/jsry_apply/user_hjmc/detail.vue'),
		  name: 'HjmcDetail',
		  meta: {
			title: '获奖名称详情'
		  },
		  children: null
		},
		{
		  path: '/team_teacher',
		  component: () => import('../views/common/team_teacher/index.vue'),
		  name: 'Teamteacher',
		  meta: {
			title: '部门教师'
		  },
		  children: null
		},
		{
		  path: '/student',
		  component: () => import('../views/student/student/index.vue'),
		  name: 'Student',
		  meta: {
			title: '学生管理'
		  },
		  children: null
		},
		{
		  path: '/student_record',
		  component: () => import('../views/student/student_record/index.vue'),
		  name: 'studentRecord',
		  meta: {
			title: ''
		  },
		  children: null
		},
		{
		  path: '/student_statistics',
		  component: () => import('../views/student/statistics/index.vue'),
		  name: 'studentStatistics',
		  meta: {
			title: ''
		  },
		  children: null
		},
		{
		  path: '/statistics_view',
		  component: () => import('../views/student/statistics/view2.vue'),
		  name: 'StatisticsView',
		  meta: {
			title: '问卷统计'
		  },
		  children: null
		},
		{
		  path: '/banners',
		  component: () => import('../views/operate/banners/index.vue'),
		  name: 'Banners',
		  meta: {
			title: '轮播图'
		  },
		  children: null
		},
		{
		  path: '/article_type',
		  component: () => import('../views/operate/article_type/index.vue'),
		  name: 'ArticleType',
		  meta: {
			title: '文章类型'
		  },
		  children: null
		},
		{
		  path: '/article',
		  component: () => import('../views/operate/article/index.vue'),
		  name: 'ArticleType',
		  meta: {
			title: '文章管理'
		  },
		  children: null
		},
		{
		  path: '/article_edit',
		  component: () => import('../views/operate/article/edit.vue'),
		  name: 'ArticleEdit',
		  meta: {
			title: '文章编辑'
		  },
		  children: null
		},
		{
		  path: '/feedback',
		  component: () => import('../views/operate/feedback/index.vue'),
		  name: 'Feedback',
		  meta: {
			title: '意见反馈'
		  },
		  children: null
		},
		{
		  path: '/export_other',
		  component: () => import('../views/system/export_other/index.vue'),
		  name: 'ExportOther',
		  meta: {
			title: '竞赛导出'
		  },
		  children: null
		},
	],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
	// 获取token
	let token = Vue.prototype.VueCookies.get("token")
	if(token){ // 已登录
	    // 防止重复登录
		if(to.path === '/login'){
			// Vue.prototype.$message.error('请不要重复登录');
			return next({path:from.path ? from.path : '/'})
		}
		//其他验证
		// 验证权限
		// let rules = window.sessionStorage.getItem('rules')
		// rules = rules ? JSON.parse(rules) : []

		// let index = rules.findIndex(item=>{
		// 	return  item.rule_id > 0 && item.desc === to.name
		// })
		// if(index === -1){
		// 	Vue.prototype.$message.error('你没有权限')
		// 	return next({name:from.name ? from.name : '/'})
		// }
		
	    next();
	}else{
		// 跳过登录页验证
		if(to.path === '/login'){
			return next();
		}
		// 未登录
		// Vue.prototype.$message.error('请先登录');
		next({ path:'/login' })
	}
})

export default router
