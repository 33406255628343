<template>
	<el-dialog title="图片管理" :visible.sync="imageModel" width="60%" top="5vh">
		<el-container style="position: relative;height: 62vh;margin: -30px -20px;">
		  <el-header class="flex align-center border-bottom">
			  <!-- 头部 -->
			  <div class="flex align-center mr-auto">
			  	<el-select v-model="searchFrom.order" placeholder="请选择图片排序方式" size="mini" class="mr-2" style="width: 160px;">
			  		<el-option label="降序" value="desc"></el-option>
			  		<el-option label="升序" value="asc"></el-option>
			  	</el-select>
			  	<el-input v-model="searchFrom.keyword" placeholder="请输入相册名称" size="mini" class="mr-2" style="width: 150px;"></el-input>
				<el-button type="primary" size="mini" @click.stop.native="seaechAlbum">搜索</el-button>
				<el-button type="primary" size="mini" @click.stop.native="openalbumModel(false)">创建相册</el-button>
				<el-upload
				  class="upload-demo mx-1"
				  action="/manage/images/upload"
				  :limit="5"
				  multiple
				  :data={album_id:albumId}
				  :show-file-list='false'
				  :on-success="uploadSuccess"
				  :before-upload="beforeUpload"
				  >
				  <el-button size="mini" type="primary">添加图片</el-button>
				</el-upload>
				<el-button type="primary" size="mini" @click="unChoose" v-if="chooseList.length">取消选中</el-button>
				<el-button type="danger" size="mini" @click="imageDelAll" v-if="chooseList.length">批量删除</el-button>
			  </div>

		  </el-header>
		  <el-container>
		    <el-aside width="200px" class="border-right bg-white" style="position: absolute;left: 0;top: 60px;bottom: 0px;">
				<!-- 侧边 -->
		    <ul class="w-100 flex flex-column">
					<album-item
					 v-for="(item,index) in albums"
					 :key="index"
					 :item="item"
					 :index="index"
					 :active="albumActive === index"
					 @itemChange="albumChange"
					 @albumModelChange="openalbumModel"
					 @albumDelChange="albumDel"
	         :showDropdown="true"
					 ></album-item>
				</ul>
				<div class="flex-all w-100 border-top" style="flex-shrink: 0;position: absolute;bottom: 0;height: 60px;">
					<el-button-group>
						<el-button size="mini" class="mr-2" :disabled="albumPage === 1">上一页</el-button>
						<el-button size="mini" :disabled="albumPage === Math.ceil(albumTotal / 10)">下一页</el-button>
					</el-button-group>
				</div>
			</el-aside>

		    <el-container>
		      <el-main style="position: absolute;left: 200px;top: 60px;bottom: 0px;right: 0;">
				  <!-- 图片列表 -->
				  <el-row :gutter="10">
				  	<el-col :span="24" :lg="4" :md="6" :sm="8" v-for="(item,index) in imagesList" :key="index">

							<el-card class="box-card mb-2 position-relative" :body-style="{'padding':0}" shadow="hover" style="cursor: pointer;">
							  <div class="border" :class="{'border-danger':item.choose}">
								<span class="badge badge-danger" style="position: absolute;top: 0;right: 0;" v-if="item.choose">{{item.order}}</span>
								<img :src="item.url" class="w-100" style="height: 100px;" @click="chooseImage(item)">
								<div class="w-100 text-white px-1 text-ellipsis-1" style="background: rgba(0,0,0,0.5);margin-top: -17px;position: absolute;">
									<span class="">{{item.name | sub }}</span>
								</div>
								<div class="w-100 flex-all">
									<el-button-group class="w-100 flex-all mb-1">
									  <el-button icon="el-icon-edit" size="mini" class="p-1" @click="editImage(item,index)"></el-button>
									  <el-button icon="el-icon-delete" size="mini" class="p-1" @click="delImage(item,index)"></el-button>
									</el-button-group>
								</div>
							  </div>
							</el-card>

					</el-col>
					<div class="flex-all text-secondary" v-if="imagesList.length === 0">
						暂没有图片
					</div>
				  </el-row>
				  <el-footer class="border-top flex align-center bg-white px-0" style="position: absolute;bottom: 0;left: 0;right: 0;">
					  <!-- 底部 -->
					  <div class="px-2 w-100" style="flex: 1;">
						 <el-pagination
						   @current-change="currentChange"
						   @size-change="sizeChange"
						   :current-page="page.current"
						   :page-sizes="page.sizes"
						   :page-size="page.size"
						   :total="page.total">
						 </el-pagination>
					  </div>
				  </el-footer>
			  </el-main>
		    </el-container>
		  </el-container>

		</el-container>

		<div slot="footer" class="dialog-footer">
			<el-button @click="hide">取 消</el-button>
			<el-button type="primary" @click="confirm">确 定</el-button>
		</div>
		<!-- 修改创建相册 -->
		<el-dialog :title="abbumModelTitle" :visible.sync="albumModel">
			<el-form ref="albumForm" :model="albumForm" label-width="80px">
				<el-form-item label="相册名称">
					<el-input v-model="albumForm.name" placeholder="请输入相册名称" size="medium"></el-input>
				</el-form-item>
				<el-form-item label="排序">
					<el-input-number v-model="albumForm.sort" :min="0" size="medium"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="albumModel = false">取 消</el-button>
				<el-button type="primary" @click="albumModelConfirm">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 修改图片 -->
		<el-dialog title="修改图片" :visible.sync="editImageModel">
			<el-form ref="imageForm" :model="imageForm" label-width="80px">
				<el-form-item label="图片名称">
					<el-input v-model="imageForm.name" placeholder="请输入图片名称" size="medium" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="归属相册">
					<el-select v-model="imageForm.album_id" placeholder="请选择归属相册" style="width: 300px;" @change="changeImageAlbum">
						<el-option label="请选择相册" value="0"></el-option>
						<el-option :label="item.name" :value="item.id" v-for="(item,index) in albums" :key="index"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="editImageModel = false">取 消</el-button>
				<el-button type="primary" @click="submitImageModel">确 定</el-button>
			</div>
		</el-dialog>
	</el-dialog>

</template>

<script>
import albumItem from "@/components/image/album_item.vue"
export default {
	components:{ albumItem },
	props:{
		//默认最多选中数量
		max:{
			type:Number,
			default:9
		}
	},
	data() {
		return {
			page:{
				current:1,
				size:24,
				total:0
			},
			imageModel: false,
			callback:false,
			searchFrom: {
				order:"desc",
				keyword:""
			},
			albums:[],
			albumActive:0,
			albumId:0,
			albumPage:1,
			albumTotal:0,
			imagesList:[],
			chooseList:[],
			albumModel:false,
			albumForm:{
				name:"",
				sort:100,
			},
			uploadImageModel:false,
			previewModel:false,
			previewUrl:"",
			editImageModel:false,
			imageForm:{
				name:'',
				album_id:0
			},
			loading:false,
		}
	},
	created() {
		this.getAlbumList()

	},
	computed:{
		abbumModelTitle(){
			return (this.albumEditIndex == -1) ? '创建相册' : '编辑相册'
		}
	},
	watch:{
		// searchFrom: {
		//       handler (val) {
		//         this.getImagesList()
		//       },
		//       deep: true
		// }
	},
	methods:{
		  submitImageModel(){
			  this.axios.post('/images/edit',this.imageForm,{
			  	token:true
			  }).then(res=>{
			  	if(res.status){
			  		this.getImagesList()
					  this.editImageModel = false
			  		this.$message.success(res.msg)
			  	}
			  })
		  },
		  changeImageAlbum(e){
			 this.imageForm.album_id = e
		  },
		  seaechAlbum(){
			  this.getImagesList()
		  },
		  uploadSuccess(e){
			  this.closeLoading()
			  this.getImagesList()
		  },
		  beforeUpload(){
			  this.openLoading()
		  },
		  //Loading加载
		  openLoading() {
			  this.loading = this.$loading({
				lock: true, // 是否锁屏
				text: '图片上传中', // 加载动画的文字
				spinner: 'el-icon-loading', // 引入的loading图标
				// background: 'rgba(0, 0, 0, 0.8)',// 背景颜色
				target: '.el-dialog .el-dialog__body .el-main', // **需要遮罩的区域
			  });
		  },
		  closeLoading(){
		     this.loading.close();
		  },
		  uploadImage(){
			this.uploadImageModel = true
		  },
		  selImage(callback){
		    //取消图片选中
		    this.unChoose()
			this.callback = callback
			this.imageModel = true
		  },
		  hide(){
			this.imageModel = false
			this.callback = false
		  },
		  confirm(){
			  //选中图片
			  if(typeof this.callback === 'function'){
				  this.callback(this.chooseList)
			  }
			  //隐藏
			  this.hide()
		  },
		  getAlbumList(){
			  this.axios.get('/album/list',{
				  token:true
			  }).then(res=>{
				  this.albums = res.data
				  this.albumTotal = res.total
				  this.albumId = (res.data == undefined) ? 0 : res.data[0].id
				  this.$nextTick(()=>{
					  this.getImagesList()
				  })
			  })
		  },
		  getListUrl(){
		  	return `/images/list?page=${this.page.current}&limit=${this.page.size}&album_id=${this.albumId}&keyword=${this.searchFrom.keyword}&order=${this.searchFrom.order}`
		  },
		  getImagesList(){
			  let url = this.getListUrl()
			  this.axios.get(url,{
			  	token:true
			  }).then(res=>{
				  if(res.status){
					  this.imagesList = res.data
					  this.page.total = res.total
				  }

			  })
		  },
		  albumChange(index){
		  	this.albumActive = index
			this.albumId = this.albums[index].id
			this.getImagesList()
		  },
		  editImage(item,index){
			  this.imageForm = {
			  	id:item.id,
			  	name:item.name,
			  	album_id:item.album_id
			  }
			  this.editImageModel = true
		  },
		  delImage(item,index){
		  	this.$confirm('确定删除该图片吗, 是否继续?', '提示', {
		  		confirmButtonText: '确定',
		  		cancelButtonText: '取消',
		  		type: 'warning'
		  	}).then((res) => {
				this.axios.post('/images/delete',{
					id:item.id
				},{
					token:true
				}).then(res=>{
					if(res.status){
						this.imagesList.splice(index,1)
						this.$message.success(res.msg)
					}
				})
		  	}).catch(() => {
		  		//取消
		  	});
		  },
		  chooseImage(item){
		  	//选中
		  	if(!item.choose){
				//判断最大选中数量
				if(this.chooseList.length >= this.max){
					return this.$message({
						message: '最多选择'+this.max+'张图片',
						type: 'warning'
					});
				}
		  		this.chooseList.push({id:item.id,url:item.url})
		  		item.order = this.chooseList.length
		  		item.choose = !item.choose
		  		return
		  	}
		  	//取消
		  	//查找索引
		  	let i = this.chooseList.findIndex(v=>v.id === item.id)
		  	if(i === -1) return
		  	//重新计算序号
		  	let length = this.chooseList.length
		  	if(i+1 < length){
		  		for (let j = i; j < length; j++) {
		  			let no = this.imagesList.findIndex(v=>v.id == this.chooseList[j].id)
		  			if(no > -1){
		  				this.imagesList[no].order--
		  			}
		  		}
		  	}
		  	//删除选中列表
		  	this.chooseList.splice(i,1)
		  	//修改状态
		  	item.choose = false
		  	//重置序号
		  	item.order = 0
		  },
		  //批量删除
		  imageDelAll(){
		  	this.$confirm('确定批量删除图片吗, 是否继续?', '提示', {
		  		confirmButtonText: '确定',
		  		cancelButtonText: '取消',
		  		type: 'warning'
		  	}).then(() => {
		  		let list = this.imagesList.filter(img=>{
		  			return !this.chooseList.some(v=>{
		  				return v.id === img.id
		  			})
		  		})
		  		this.imagesList = list
		  		this.chooseList = [];
		  	    this.$message({
		  	    	message: '删除成功',
		  	    	type: 'success'
		  	    });
		  	}).catch(() => {
		  		//取消
		  	});
		  },
		  //分页
		  sizeChange(val) {
		  	this.page.size = val
		  	this.getImagesList()
		  },
		  currentChange(val){
		  	this.page.current = val
		  	this.getImagesList()
		  },
		  //取消选中
		  unChoose(){
		  	this.imagesList.forEach(item=>{
		  		let i = this.chooseList.findIndex(v=>{
		  			return v.id === item.id
		  		})
		  		if(i > -1){
		  			//取消选中
		  			item.choose = false
		  			item.order = 0
		  			this.chooseList.splice(i,1)
		  		}
		  	})
		},
		openalbumModel(obj){
			//修改
			if(obj){
				let {item,index} = obj
				//初始化
				this.albumForm.id = item.id
				this.albumForm.name = item.name
				this.albumForm.sort = item.sort
				this.albumEditIndex = index
				//打开模态框
				return this.albumModel = true
			}
			//创建
			this.albumForm = {
				name:'',
				sort:100
			}
			this.albumEditIndex = -1
			this.albumModel = true
		},
		albumModelConfirm(){
			if(this.albumEditIndex > -1){
				//修改
				this.albumEdit()
				this.axios.post('/album/edit',this.albumForm,{
					token:true
				}).then(res=>{
					if(res.status){
						this.$message.success(res.msg)
					}
				})
				return this.albumModel = false
			}
			//追加
			this.axios.post('/album/edit',this.albumForm,{
				token:true
			}).then(res=>{
				if(res.status){
					this.albums.unshift({
						id:parseInt(res.data),
						name:this.albumForm.name,
						sort:this.albumForm.sort,
						num:0,
					})
					this.$message.success(res.msg)
				}
			})
			this.albumModel = false
		},
		albumEdit(){
			this.albums[this.albumEditIndex].id = this.albumForm.id
			this.albums[this.albumEditIndex].name = this.albumForm.name
			this.albums[this.albumEditIndex].sort = this.albumForm.sort
		},
		albumDel(index){
			this.$confirm('确定删除该相册, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then((res) => {
				this.axios.post('/album/delete',{
					id:this.albums[index].id
				},{
					token:true
				}).then(res=>{
					if(res.status){
						this.albums.splice(index,1)
						this.$message.success(res.msg)
					}
				})
			}).catch(() => {
				this.$message({
					message: '已取消删除',
					type: 'success'
				});
			});
		},
	},
  filters: {
    sub(item) {
      return item.substr(0,8)
    }
  },
}
</script>

<style>
/* .v-modal{
	background: none!important;
} */
.el-aside{
  padding: 0!important;
  border-radius:0!important;
}
.border-danger {
    border-color: #dc3545!important;
}
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
</style>
