export default {
	state:{
       shop_name:'',
	   company_name:'',
	   shop_address:'',
	   shop_desc:'',
	   shop_beian:'',
	   kf_mobile:'',
	   search_keywords:'',
	   shop_logo:'',
	   system_name:'',
	   dkl_bjs:'',
	   dkl_zkss:'',
	   dkl_xs:'',
	   jsry_1_name:'',
	   jsry_1_score:'',
	   jsry_2_name:'',
	   jsry_2_score:'',
	   jsry_3_name:'',
	   jsry_3_score:'',
	   jsry_4_name:'',
	   jsry_4_score:'',
	   bs_password:'',
	},
	getters:{
		getData: state => state
	},
	mutations:{
       //修改state
       editState(state,{key,val}){
       	 state[key] = val
       },
	   editParamsState(state,{name,key,val}){
	   	 state[name][key] = val
 	   },
	   initSetting(state,data){
		   data.map(v=>{
			   var reg = /{/g;
			   var res = v.value.match(reg);
			   if(res != null){
				   let obj = JSON.parse(v.value)
				   for(var i in obj) {
					   console.log(obj)
				   		state[v.skey][i] = obj[i]
				   }
			   }else{
				   state[v.skey] = v.value
			   }
		   })
	   }
	},
	actions:{
		
	}
}