import { Message } from 'element-ui'
export default {
	// sku排列算法
	cartesianProductOf() {
		return Array.prototype.reduce.call(arguments,function(a, b) {
			var ret = [];
			a.forEach(function(a) {
				b.forEach(function(b) {
					ret.push(a.concat([b]));
				});
			});
			return ret;
		}, [[]]);
	},
	swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    // 上移 将当前数组index索引与后面一个元素互换位置，向数组后面移动一位
    moveUp(arr, index) {
      this.swapArray(arr, index, index - 1);
    },
    // 下移 将当前数组index索引与前面一个元素互换位置，向数组前面移动一位
    moveDown(arr, index) {
      this.swapArray(arr, index, index + 1);
    },
	//验证是否是手机号
	isPhoneNumber(str) {
		var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
		if (!myreg.test(str)) {
			return false
		} else {
			return true
		}
	},
	success(msg){
		Message.success({
			message: msg,
			type: 'success'
		});
	},
	error(msg){
		Message.error(msg);
	},
	//判断是否是数字
	isNumber(val){
	    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
	    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
	    if(regPos.test(val) || regNeg.test(val)){
	        return true;
	    }else{
	        return false;
	    }
	}
}