<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
	<!-- 图片查看 -->
	<preview ref="preview" :previewModel="previewModel" :previewUrl="previewUrl" @changePreview="changePreview"></preview>
  </div>
</template>

<script>
	import imageDialog from "@/components/image/image_dialog.vue"
	import preview from "@/components/image/preview.vue"
	export default {
		components: {
		  	 imageDialog,
		     preview,
		},
		data() {
			return {
				maxChooseImage: 9,
				previewModel:false,
				previewUrl:'',
				isRouterAlive : true,
			}
		},
		provide(){
		  return {
			  app:this
		  }
		},
		created() {
			document.title = '业务考核积分系统'
			this.shopConf()
		},
		methods:{
			//选择图片
			chooseImage(callback,max=9){
			  this.maxChooseImage = max
			  this.$refs.imageDialog.selImage(callback)
			},
			previeImage(url){
			  this.previewModel = true
			  this.previewUrl = url
			},
			changePreview(e){
			  this.previewModel = e
			},
			shopConf(){
			  this.axios.get('/manage/shopConf')
			  .then(res=>{
				this.$nextTick(()=> {
				  this.$store.commit('config',res.data)
				  document.title = res.data.shop_name || '业务考核积分系统';
				})
			  })
			},
			//刷新页面
			reload(){
			  this.isRouterAlive = false
			  this.$nextTick(()=>{
				  this.isRouterAlive = true
			  })
			}
		}
	}
</script>
<style lang="scss">
@import url("./assets/css/reset.css");
@import url("./assets/css/common.css");

</style>
